<template>
  <div class="bg-white p-4 md:p-6  rounded-lg modal-md pb-4">
    <div class="text-xl my-8 mt-4 font-semibold font-merri text-center">
      Remove Cat
    </div>
    <p>
      Are you sure you want to remove
      <span class="font-semibold">{{ data.cat.name }}</span
      >?
    </p>
    <p>
      This will completely remove them from your subscription, this cannot be
      undone.
    </p>

    <form @submit.prevent="removeCat" novalidate="true">
      <loading-button
        :is-loading="isLoading"
        :is-enabled="true"
        :is-full="true"
        class="mt-4"
        caution
        label="Remove"
      />
    </form>
    <div class="text-center text-gray-600 mt-6">
      <a @click="closeModal">Cancel</a>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import http from '@/http';
import { mapGetters } from 'vuex';
import { cloneDeep, find } from 'lodash/fp';
export default {
  props: ['data'],
  components: {
    LoadingButton
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    })
  },
  methods: {
    deleteCat() {
      this.$store
        .dispatch('removeCat', this.data.cat.id)
        .then(() => {
          this.$store.dispatch('getSubscription').then(() => {
            this.isLoading = false;
            this.closeModal();
          });
        })
        .catch(error => {
          this.isLoading = false;
          this.$store.dispatch('sendMpEvent', {
            event: `Remove Cat - Error`,
            properties: {
              location: this.$route.name,
              catId: this.data.cat.id,
              cat: this.data.cat.name,
              error: error.response.data.error.message
                ? error.response.data.error.message
                : 'Unknown Error',
              type: 'EVT'
            }
          });
        });
    },
    removeCat: function() {
      this.isLoading = true;
      if (this.data.cat.active) {
        const items = cloneDeep(this.subscription.items);
        const selectedCat = find(['id', this.data.cat.id], items);

        selectedCat.active = false;

        http
          .put(this.subscription.links.adjust, {
            items: items,
            delivery: this.subscription.deliveryTypeUserSelected
          })
          .then(() => {
            this.$store.dispatch('sendMpEvent', {
              event: `Remove cat  - Success`,
              properties: {
                location: this.$route.name,
                catId: this.data.cat.id,
                cat: this.data.cat.name,
                type: 'EVT'
              }
            });

            this.deleteCat();
          })
          .catch(error => {
            this.isLoading = false;
            this.$store.dispatch('sendMpEvent', {
              event: `Remove Cat - Error`,
              properties: {
                location: this.$route.name,
                catId: this.data.cat.id,
                cat: this.data.cat.name,
                error: error.response.data.error.message
                  ? error.response.data.error.message
                  : 'Unknown Error',
                type: 'EVT'
              }
            });

            throw Error(error.response.data.error.message);
          });
      } else {
        this.deleteCat();
      }
    },
    closeModal: function() {
      this.$parent.$emit('make-close');
    }
  },

  mounted() {
    this.$store.dispatch('sendMpEvent', {
      event: `Make cat ${this.data.makeActive ? 'active' : 'inactive'} - View`,
      properties: {
        location: this.$route.name,
        catId: this.data.cat.id,
        cat: this.data.cat.name,
        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
